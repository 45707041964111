import gql from 'graphql-tag';

export const GET_CONFIG_LEVELS = gql`
  query LevelConfigs($orgId: UUID!) {
    levels {
      id
      orderIndex
      name
      category
      sanctionType
      configuration(id: $orgId) {
        contextId
        levelId
        eventLengthRange {
          maximumDays
          minimumDays
        }
        skillLevel
        sanctionType
        enabled
      }
    }
  }
`;
