import React, { useCallback, useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { tournamentsClient } from 'src/apollo/client';
import { useOrgId } from 'src/apollo/local-state';
import AdminTable, { DataCols } from 'src/components/admin-table/admin-table';
import Dropdown from 'src/components/dropdown/dropdown';
import FilterSet, { Filter } from 'src/components/filter-set/filter-set';
import PageHeader from 'src/components/page-header/page-header';
import Panel from 'src/components/panel/panel';
import StatusLabel from 'src/components/status-label/status-label';
import {
  Category,
  SanctionType as SanctionTypeType,
  SkillLevel as SkillLevelType,
} from 'src/graphql-types/globalTournamentTypes';
import { LevelConfigs_levels as Level, LevelConfigs, LevelConfigsVariables } from 'src/graphql-types/LevelConfigs';
import useFilters, { UrlParams } from 'src/hooks/use-filters';

import { GET_CONFIG_LEVELS } from './level-config-overview-queries';
import * as styles from './level-config-overview.module.less';

const { ADVANCED, INTERMEDIATE, HIGH_INTERMEDIATE, LOW_INTERMEDIATE, BEGINNER, NONE: SKILL_NONE } = SkillLevelType;
const { NATIONAL, SECTION, DISTRICT, NONE } = SanctionTypeType;

interface LevelConfigFilter {
  category: Category;
}

const LevelConfigOverview: React.FC = () => {
  const { t } = useTranslation();
  const orgId = useOrgId();

  const { data, loading, error } = useQuery<LevelConfigs, LevelConfigsVariables>(GET_CONFIG_LEVELS, {
    client: tournamentsClient,
    variables: { orgId },
  });

  const columns = useMemo<DataCols<Level>>(() => {
    return [
      { key: 'name', title: t('level'), getValue: (l) => l.name },
      { key: 'days', title: t('days'), getValue: (l) => <DaysRange level={l} /> },
      { key: 'skill', title: t('skill level'), getValue: (l) => <SkillLevel level={l} /> },
      {
        key: 'sanction type',
        title: t('sanction type'),
        getValue: (l) => <SanctionType level={l} />,
      },
      {
        key: 'enabled',
        title: ' ',
        getValue: (l) => {
          const enabled = l.configuration.enabled;
          return (
            <div className={styles.enableLabel}>
              <StatusLabel variety={enabled ? 'success' : 'neutral'}>{t(enabled ? 'enabled' : 'disabled')}</StatusLabel>
            </div>
          );
        },
      },
    ];
  }, [t]);

  const toValue = useCallback((f: string) => f.toUpperCase(), []);
  const toUrlParam = useCallback((f: string) => f.toLowerCase(), []);
  const initialFilter = useMemo(() => ({ category: Category.JUNIOR }), []);
  const urlParams = useMemo<UrlParams<LevelConfigFilter>[]>(() => [{ filterKey: 'category', toUrlParam, toValue }], []);
  const filterArgs = useMemo(() => ({ initialFilter, urlParams }), [initialFilter, urlParams]);
  const [{ category }, updateFilters] = useFilters(filterArgs);

  const sortedData = useMemo(() => {
    return data?.levels
      ?.filter((l) => l.sanctionType !== NONE && l.category === category)
      .sort((l1, l2) => l1.orderIndex - l2.orderIndex);
  }, [data, category]);

  return (
    <>
      <div>
        <PageHeader title={t('level configurations')} />
      </div>
      <Panel>
        <FilterSet spacing={{ margins: { md: 'bottom' } }}>
          <Filter label={t('circuit')}>
            <Dropdown
              selected={category}
              options={[
                { label: t('junior'), value: Category.JUNIOR },
                { label: t('adult'), value: Category.ADULT },
                { label: t('wheelchair'), value: Category.WHEELCHAIR },
                { label: t('wtn play'), value: Category.WTN_PLAY },
                { label: t('pickleball'), value: Category.PICKLE_BALL },
              ]}
              onSelect={({ value }) => updateFilters({ category: value as Category })}
            />
          </Filter>
        </FilterSet>
        <AdminTable
          loading={loading}
          error={error?.message}
          columns={columns}
          data={sortedData}
          onRowClick={(r) => navigate(`/tournaments/level-config/${r.id}`)}
        />
      </Panel>
    </>
  );
};

interface LevelProps {
  level: Level;
}

const DaysRange: React.FC<LevelProps> = ({
  level: {
    configuration: { eventLengthRange },
  },
}) => {
  const max = eventLengthRange?.maximumDays;
  const min = eventLengthRange?.minimumDays;
  const { t } = useTranslation();
  if (max && min) {
    return t('level days min max', { min, max });
  } else if (max || min) {
    return t(`level days ${min ? 'min' : 'max'}`, { days: min || max });
  }
  return t('n/a');
};

const SkillLevel: React.FC<LevelProps> = ({ level }) => {
  const { t } = useTranslation();
  switch (level.configuration.skillLevel) {
    case SKILL_NONE:
      return t('none');
    case BEGINNER:
      return t('beginner');
    case LOW_INTERMEDIATE:
      return t('low intermediate');
    case INTERMEDIATE:
      return t('intermediate');
    case HIGH_INTERMEDIATE:
      return t('high intermediate');
    case ADVANCED:
      return t('advanced');
    default:
      return t('n/a');
  }
};

const SanctionType: React.FC<LevelProps> = ({ level }) => {
  const { t } = useTranslation();
  switch (level.configuration.sanctionType) {
    case NATIONAL:
      return t('national');
    case SECTION:
      return t('section');
    case DISTRICT:
      return t('district');
    case NONE:
      return t('none');
    default:
      return t('n/a');
  }
};

export default LevelConfigOverview;
